import yoktez_logo from './images/yoktez.png';
import school_logo from './images/scholar_logo.svg';
import orcid_logo from './images/orcid_icon.svg';

export const Assets = {
    yoktez_logo: yoktez_logo,
    school_logo: school_logo,
    orcid_logo: orcid_logo
}

export default Assets;