// ProjectCard.tsx

import React from 'react';
import "../styles/project.css";
import Project from '../types/Project';
interface ProjectCardProps {
  project: Project;
}

const ProjectCard: React.FC<ProjectCardProps> = ({ project }) => {
  const { title, description, imageUrl, githubUrl, playStoreUrl } = project;

  return (
    <div className="project__card__container">
        {imageUrl && (
        <div className="project__image">
            <img src={imageUrl} alt={title} className="project__image"/>
        </div>
        )}
        <div className="project__information-container">
            <h3 className="project__title" rel={title}>{title}</h3>
            <p className="project__description">{description}</p>
            <div className="project__links">
                {githubUrl && (
                    <a href={githubUrl} target="_blank" rel="noopener noreferrer" className="project__link-icon">
                        <i className="uil uil-github"></i>
                    </a>
                )}
                {playStoreUrl && (
                    <a href={playStoreUrl} target="_blank" rel="noopener noreferrer" className="project__link-icon">
                        <i className="uil uil-google-play"></i>
                    </a>
                )}
            </div>
        </div>
    </div>
  );
};

export default ProjectCard;
