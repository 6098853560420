import React from "react";
import ReactHtmlParser from "html-react-parser";
import * as DOMPurify from 'dompurify';

interface MainDataProps {
    content: {
        title: string;
        subtitle: string;
        paragraph: string;
    };
}

const MainData: React.FC<MainDataProps> = ({ content }) => {
    const cleanHTML = (html: string) => {
        const config = {
            ADD_ATTR: ['className', 'target', 'rel', 'href'], // Allow these attributes
            ALLOWED_TAGS: ['a', 'p', 'div', 'span'], // Add any other tags you need
        };
        return DOMPurify.sanitize(html, config);
    };
    return (
        <div>
            <div className="home__data">
                <h1 className="home__title">{content.title}</h1>
                <h2 className="home__subtitle">{content.subtitle}</h2>
                <p className="home__description">{ReactHtmlParser(cleanHTML(content.paragraph))}</p>
                <a href="/contact" className="button button--flex">
                    Contact Me <i className="uil uil-message button__icon"></i>
                </a>
            </div>
        </div>
    );
};

export default MainData;
