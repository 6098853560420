interface SocialProps {
    social: {
        linkedin: string;
        github: string;
        instagram: string;
        twitter: string;
    };
}

const Social: React.FC<SocialProps> = ({social}) => {
    return (
        <div className="home__social">
            <a href={social.linkedin} className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-linkedin-alt"></i>
            </a>
            <a href={social.github} className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-github-alt"></i>
            </a>
            <a href={social.instagram} className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-instagram-alt"></i>
            </a>
            <a href={social.twitter} className="home__social-icon" target="_blank" rel="noreferrer">
                <i className="uil uil-twitter-alt"></i>
            </a>
        </div>
    )
}

export default Social;