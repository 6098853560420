import React, {useEffect} from 'react';
import '../styles/about.css';
import Assets from "../assets/Assets";
import {readAboutContent} from "../data/firebase_client";
import Education from "../types/Education";
import Experience from "../types/Experience";

const About: React.FC = () => {

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [ name, setName] = React.useState('');
    const [ profession, setProfession] = React.useState('');
    const [ about, setAbout] = React.useState('');
    const [ educations, setEducations] = React.useState<Education[]>([]);
    const [ experiences, setExperiences] = React.useState<Experience[]>([]);
    const [ profilePhoto, setProfilePhoto ] = React.useState('');
    const [ orcid, setOrcid ] = React.useState('');
    const [ scholar, setScholar ] = React.useState('');
    const [ yoktez, setYoktez ] = React.useState('');

    useEffect(() => {
        setLoading(true);

        const fetchData = async () => {
            try {
                const data = await readAboutContent();
                setName(data.name);
                setProfession(data.profession);
                setAbout(data.about);
                setEducations(data.educations);
                setExperiences(data.experiences);
                setProfilePhoto(data.profile_photo);
                setOrcid(data.orcid);
                setScholar(data.scholar);
                setYoktez(data.yoktez);
                setError('');
            } catch (error) {
                console.error(error);
                setError("An error occurred while fetching data");
            }
        };
        setLoading(true);
        fetchData().then(
            () => {
                setLoading(false);
            }
        ).catch(
            (_) => {
                setLoading(false);
            }
        );

        const interval = setInterval(() => {
            fetchData();
        }, 300000);
        return () => clearInterval(interval);
    }, []);
    return (
        <section className="about section" id="about">
            {
                loading ? (
                    <div className="loading-container">
                        <div className="lds-ring">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                ) : (
                    error.length > 0 ? (
                        <div className="error-container">
                            <span>{error}</span>
                        </div>
                        ) : (
                        <div className='about__container container grid'>
                            <section className="about__preview container grid">
                                <div style={{
                                    background: `url(${profilePhoto}) no-repeat center`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center'
                                }} className="about__preview-img"  />
                                <div className="about__preview-content">
                                    <h2 className="section__title">About Me</h2>
                                    <span
                                        className="section__subtitle about__preview-subtitle">I'm {name}. I'm {profession}</span>
                                    <p className="about__preview-description">{about}</p>
                                </div>
                            </section>
                            <section className="about__education container grid">
                                <h2 className="section__title">Education</h2>
                                <section className="academic__links">
                                    <a href={orcid} target="_blank" rel="noreferrer">
                                        <img alt="ORCID logo"
                                             src={Assets.orcid_logo}
                                             width="24" height="24"/>
                                    </a>
                                    <a href={scholar} target="_blank" rel="noreferrer">
                                        <img alt="SCHOLAR logo"
                                             src={Assets.school_logo}
                                             width="24" height="24"/>
                                    </a>
                                    <a href={yoktez} target="_blank" rel="noreferrer">
                                        <img alt="YÖK Thesis logo"
                                             src={Assets.yoktez_logo}
                                             width="24" height="24"/>
                                    </a>
                                </section>
                                <div className="about__education-content">
                                    {educations.map((education) => (
                                        <div key={education.id} className="education__content">
                                            <h3 className="education__title">{education.school}</h3>
                                            <span
                                                className="education__subtitle">{education.degree} at {education.studyField} ({education.startDate} - {education.endDate})</span>
                                            <p className="education__description">{education.description}</p>
                                        </div>
                                    ))}
                                </div>
                            </section>
                            <section className="about__experience container grid">
                                <h2 className="section__title">Experience</h2>
                                <div className="experience-content">
                                    {experiences.map((experience) => (
                                        <div key={experience.company} className="about__experience-content">
                                            <h3 className="about__experience-title">{experience.company}</h3>
                                            <span className="about__experience-subtitle">{experience.position}</span>
                                            <span
                                                className="about__experience-year"> ({experience.startDate} - {experience.endDate}) </span>
                                            <p className="about__experience-description">{experience.description}</p>
                                            <div className="about__experience-projects grid">
                                                {experience.skills.map((skill) => (
                                                    <div key={skill.id} className="about__experience-project">
                                                        <span className="about__experience-tag">{skill.name}</span>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>
                        </div>
                    )
                )
            }
        </section>
    );
};

export default About;