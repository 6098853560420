import emailjs from '@emailjs/browser';
import {Options} from "@emailjs/browser/es/types/Options";

export default async function sending_mail(name: string, subject: string, email: string, message: string) {
    try {
        const service_id = `${process.env.REACT_APP_EMAIL_SERVICE_ID}`;
        const template_id = `${process.env.REACT_APP_EMAIL_TEMPLATE}`;
        const template_params: Record<string, string> = {
            "from_name": name,
            "from_email": email,
            "subject": subject,
            "message": message,
        };
        const options: Options = {
            publicKey: `${process.env.REACT_APP_EMAIL_PUBLIC_KEY}`
        };
        emailjs.init(options);

        return await emailjs.send(service_id, template_id, template_params)
            .then((response) => {
                return Promise.resolve(`success ${response.status} ${response.text}`);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    } catch (error) {
        return Promise.reject(error);
    }
}
