import React, {FormEvent, useState} from "react";
import "../styles/contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import sending_mail from "../data/sending_mail";


const Contact: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isVerified, setIsVerified] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState('');
    const [error, setError] = useState('');

    const handleRecaptchaChange = (token: string | null) => {
        if (token) {
            setIsVerified(true);
            setRecaptchaToken(token);
        } else {
            handleRecaptchaExpired();
        }
    };

    const handleRecaptchaExpired = () => {
        setIsVerified(false);
        setRecaptchaToken('');
    };

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();
        const form = e.target as HTMLFormElement; // Assuming e.target is the form element

        if (isVerified && recaptchaToken && name && email && subject && message) {
            await sending_mail(name, subject, email, message).then(_ => {
                setError('');
                alert('Email sent successfully');
                form.submit();
            }).catch(_ => {
                setError('An error occurred while sending the email. Please try again later.');
                e.preventDefault();
            });
        } else {
            setError('Please verify that you are a human and fill in all the fields');
            e.preventDefault();
        }
    };

    return (
        <div className="contact__container">
            <h2 className="section__title">Contact Me</h2>
            <form onSubmit={handleSubmit} className="contact__form">
            <input
                    type="text"
                    className="form__control"
                    id="name"
                    name="name"
                    placeholder="Full name"
                    onChange={e => {
                        setName(e.target.value);
                    }}
                    required
                />
                <input
                    type="email"
                    className="form__control"
                    id="email"
                    name="email"
                    placeholder="Email"
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    required
                />
                <input
                    type="text"
                    className="form__control"
                    id="subject"
                    name="subject"
                    placeholder="Subject"
                    onChange={(e) => {
                        setSubject(e.target.value);
                    }}
                />
                <textarea
                    className="textarea form__control"
                    id="message"
                    name="message"
                    placeholder="Message"
                    rows={7}
                    maxLength={1000}
                    onChange={(e) => {
                        setMessage(e.target.value);
                    }}
                    required
                ></textarea>
                <button
                    type="submit"
                    className="button button--flex submit__button">
                    Submit
                </button>
                <ReCAPTCHA
                    style={{
                        backgroundColor: 'transparent',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: '10px',
                        background: 'transparent'
                    }}
                    sitekey={process.env.REACT_APP_SITE_KEY as string}
                    onChange={handleRecaptchaChange}
                    onExpired={handleRecaptchaExpired}
                />
                {error && <p className="error__message">{error}</p>}
            </form>
        </div>
    );
};

export default Contact;