import React, { useEffect, useState } from 'react';
import ProjectCard from './ProjectCard';
import '../styles/projects.css';
import Project from '../types/Project';
import { readProjectsContent } from '../data/firebase_client';
import DOMPurify from 'dompurify';
import ReactHtmlParser from 'html-react-parser';

const Projects: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [content, setContent] = useState({
        title: '',
        description: '',
    });
    const [projects, setProjects] = useState<Project[]>([]);

    const cleanHTML = (html: string) => {
        const config = {
            ADD_ATTR: ['class', 'target', 'rel', 'href'], // Allow these attributes
            ALLOWED_TAGS: ['a', 'p', 'div', 'span'], // Add any other tags you need
        };
        return DOMPurify.sanitize(html, config);
    };
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const resp = await readProjectsContent();
                const githubUrl = resp.header_info.github_url;
                setContent({
                    ...resp.header_info,
                    description: resp.header_info.description.replace('%s', githubUrl),
                });
                setProjects(resp.projects);
                setError('');
            } catch (error) {
                console.error('Error fetching project content:', error);
                setError('An error occurred while fetching data');
            } finally {
                setLoading(false);
            }
        };

        setLoading(true);
        fetchProjects();

        const interval = setInterval(fetchProjects, 300000); // Refresh every 5 minutes
        return () => clearInterval(interval);
    }, []);

    return (
        <div>
            {loading && (
                <div className="loading-container">
                    <div className="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            )}
            {error && (
                <p className="error__message">
                    {error}
                </p>
            )}
            {!loading && !error && (
                <div className='projects'>
                    <div className="projects__header">
                        <h2 className="projects__title">{content.title}</h2>
                        <p className="projects__description">
                            {ReactHtmlParser(cleanHTML(content.description))}
                        </p>
                    </div>
                    <div className="projects__container-list">
                        {projects.map((project) => (
                            <ProjectCard project={project} key={project.id} />
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
}

export default Projects;
