import { db } from "./firebase";
import { get, ref, onValue, DatabaseReference } from "firebase/database";


const firebaseWebPath = process.env.REACT_APP_FIREBASE_WEB_PATH;
const firebaseHomePath = process.env.REACT_APP_FIREBASE_HOME_PATH;
const firebaseAboutPath = process.env.REACT_APP_FIREBASE_ABOUT_PATH;
const firebaseProjectsPath = process.env.REACT_APP_FIREBASE_PROJECTS_PATH;

export async function readProjectsContent() {
    const projectsPath = `${firebaseWebPath}${firebaseProjectsPath}`;
    const dbRef = ref(db, projectsPath);
    return await runTransactionOrderAndLimit(dbRef);
}

export async function readHomeContent() {
    const homePath = `${firebaseWebPath}${firebaseHomePath}`;
    const dbRef = ref(db, homePath);
    return await runTransactionOrderAndLimit(dbRef);
}

export async function readAboutContent() {
    const aboutPath = `${firebaseWebPath}${firebaseAboutPath}`;
    const dbRef = ref(db, aboutPath);
    return await runTransactionOrderAndLimit(dbRef);
}

async function runTransactionOrderAndLimit(dbRef: DatabaseReference) {
    try {
        let snapshot = await get(dbRef);
        if (snapshot.exists()) {
            let data = snapshot.val();
            data = Object.values(data)[Object.values(data).length - 1];
            console.log(data);
            return data;
        } else {
            console.log("No data available");
            return null;
        }
    } catch (error) {
        console.error(error);
        throw error;
    }
}

function runTransaction(dbRef: DatabaseReference, updateFunction: (data: any) => any) {
    onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        updateFunction(data);
    });
}

